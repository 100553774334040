import axios from "axios";
import { messaging } from "Utils/Firebase";
import config from "Config/config";
import { iterableJwtAuthentication } from "API/restAPI";
import { getInAppMessages, initializeWithConfig } from "@iterable/web-sdk";

import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { analytics } from "Analytics/index";

export async function requestPermission(email, userId) {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await messaging.getToken({
        vapidKey: config.FIREBASE_VAPID_KEY
      });
      const { data } = await iterableJwtAuthentication();
      await axios.post(
        config.iterable.registerBrowserTokenUrl,
        {
          browserToken: token,
          email: email,
          userId: userId
        },
        {
          headers: {
            "Api-Key": config.iterable.apiKey,
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.jwt}`
          }
        }
      );
    } else {
      console.log("Notifications permission was denied.");
    }

    messaging.onMessage(messaging, payload => {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body
      };
      self.registration.showNotification(
        notificationTitle,
        notificationOptions
      );
    });
  } catch (error) {
    console.log("Error registering browser token with Iterable:", error);
  }
}

export async function initializeIterable(email) {
  const initializeParams = {
    authToken: config.iterable.apiKey,
    configOptions: {
      isEuIterableService: false,
      dangerouslyAllowJsPopups: true
    },
    generateJWT: async () => {
      try {
        const res = await iterableJwtAuthentication();
        const token = res.data.jwt;
        return token;
      } catch (error) {
        console.error("Error during Iterable JWT generation:", error);
        return null;
      }
    }
  };

  const { setEmail } = initializeWithConfig(initializeParams);
  const { request, triggerDisplayMessages } = getInAppMessages(
    {
      count: 20,
      packageName: config.iterable.packageName
    },
    { display: "immediate" }
  );

  try {
    await setEmail(email);
    const { data } = await request();

    if (data.inAppMessages) {
      data.inAppMessages.forEach(message => {
        const trackingProperties = {
          campaignId: message.campaignId,
          messageId: message.messageId,
          isRead: message.read
        };

        analytics.track(AnalyticsEvents.label.iterable.trackMessageDelivered, {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.alertShown,
          label: AnalyticsEvents.label.iterable.trackMessageDelivered,
          property: JSON.stringify(trackingProperties),
          value: "",
          context: "Iterable Messaging"
        });
      });
      await triggerDisplayMessages(data.inAppMessages);
    }
  } catch (error) {
    return null;
  }
}
