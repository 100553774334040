const config = {
  restUrl: process.env.REACT_APP_REST_URL,
  graphqlUrl: process.env.REACT_APP_GRAPHQL_URL,
  graphqlUrlv2: process.env.REACT_APP_GRAPHQL_URL_V2,
  clientName: process.env.REACT_APP_CLIENT_NAME,
  gatewayUrl: process.env.REACT_APP_GATEWAY_URL,
  hephaestusDriverVettingUrl:
    process.env.REACT_APP_HEPHAESTUS_DRIVER_VETTING_URL,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    fleetRedirectUri: process.env.REACT_APP_AUTH0_FLEET_REDIRECT_URI,
    legacyRedirectUri: process.env.REACT_APP_AUTH0_LEGACY_REDIRECT_URI,
    connection: process.env.REACT_APP_AUTH0_CONNECTION
  },
  gluu: {
    token_endpoint: process.env.REACT_APP_GLUU_TOKEN_ENDPOINT,
    authority: process.env.REACT_APP_GLUU_AUTHORITY,
    sso_uri: process.env.REACT_APP_GLUU_SSO_URI,
    redirect_uri: process.env.REACT_APP_GLUU_REDIRECT_URI,
    grant_type: process.env.REACT_APP_GLUU_GRANT_TYPE,
    sso_logout_uri: process.env.REACT_APP_GLUU_SSO_LOGOUT_URI,
    sso_post_logout_uri: process.env.REACT_APP_GLUU_SSO_POST_LOGOUT_URI,
    auth_code_grant_type: process.env.REACT_APP_GLUU_AUTH_CODE_GRANT_TYPE,
    refresh_token_grant_type:
      process.env.REACT_APP_GLUU_REFRESH_TOKEN_GRANT_TYPE,
    client_id: process.env.REACT_APP_GLUU_CLIENT_ID,
    client_secret: process.env.REACT_APP_GLUU_CLIENT_SECRET,
    scope: process.env.REACT_APP_GLUU_SCOPE,
    response_type: process.env.REACT_APP_GLUU_RESPONSE_TYPE,
    acr_values: process.env.REACT_APP_GLUU_ACR_VALUES,
    facebook: process.env.REACT_APP_GLUU_FACEBOOK,
    apple: process.env.REACT_APP_GLUU_APPLE,
    google: process.env.REACT_APP_GLUU_GOOGLE
  },
  algolia: {
    appId: process.env.REACT_APP_ALGOLIA_APP_ID,
    apiKey: process.env.REACT_APP_ALGOLIA_API_KEY
  },
  branchKey: process.env.REACT_APP_BRANCH_KEY,
  REACT_APP_VERSION: process.env.REACT_APP_VERSION,
  payoutUrl: process.env.REACT_APP_WEBTASK_PAYOUT_URL,
  vinValidatorUrl: process.env.REACT_APP_VIN_VALIDATOR_URL,
  ACCESS_TOKEN_NAME: process.env.REACT_APP_ACCESS_TOKEN_NAME,
  SENDBIRD_APP_ID: process.env.REACT_APP_SENDBIRD_APP_ID,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  GOOGLE_GEOLOCATE_API_KEY: process.env.REACT_APP_GOOGLE_GEOLOCATE_API_KEY,
  GOOGLE_GEOLOCATE_API_URL: process.env.REACT_APP_GOOGLE_GEOLOCATE_API_URL,
  GOOGLE_GEOCODE_API_URL: process.env.REACT_APP_GOOGLE_GEOCODE_API_URL,
  stripe: {
    pkey: process.env.REACT_APP_STRIPE_P_KEY
  },
  firebaseConfig: {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  },
  FIREBASE_VAPID_KEY: process.env.REACT_APP_VAPID_KEY,
  iterable: {
    apiKey: process.env.REACT_APP_ITERABLE_API_KEY,
    registerBrowserTokenUrl: process.env.REACT_APP_ITERABLE_REGISTER_TOKEN_URL,
    packageName: process.env.REACT_APP_ITERABLE_PACKAGE_NAME
  }
};

export default config;
