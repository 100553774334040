const { rest } = require("Axios/rest");

export const updateUserComunicationsConfig = (marketing, transactional) =>
  rest.put("/communications/user-configs", {
    userChannels: [
      { transport: "EMAIL", active: true, category: "MARKETING" },
      { transport: "EMAIL", active: true, category: "TRANSACTIONAL" },
      { transport: "SMS", active: marketing, category: "MARKETING" },
      { transport: "SMS", active: transactional, category: "TRANSACTIONAL" }
    ]
  });

export const zendeskJwtAuthentication = () =>
  rest.post("/zendesk/jwtAuthentication");

export const iterableJwtAuthentication = () =>
  rest.post("/iterable/jwtAuthentication");

export const getCarSummaryData = ownerId =>
  rest.get(`/cars/summary/owner/${ownerId}`);

export const getUserPriceAlert = userId =>
  rest.get(`/marketing/owners/price-alert/${userId}`);

export const postponeOverpricedCarAlert = (userId, dismissalPeriod) =>
  rest.post(`/marketing/owners/price-alert/${userId}`, {
    duration: dismissalPeriod,
    marketingOrigin: null
  });
