import firebase from "firebase/app";
import "firebase/firebase-remote-config";
import config from "Config/config";
import "firebase/messaging";

firebase.initializeApp(config.firebaseConfig);
const messaging = firebase.messaging();
const remoteConfig = firebase.remoteConfig();
if (process.env.REACT_APP_STAGE !== "production")
  remoteConfig.settings.minimumFetchIntervalMillis = 3000;
if (process.env.REACT_APP_STAGE === "production")
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
export { remoteConfig, messaging };
export default firebase;
